<template>
  <div class="container">
    <div class="content">
      <div class="item item_2">
        <div class="title">核心指标</div>
        <div class="detail">
          <div
            class="itemDetail"
            v-for="(item, index) in entryData"
            :key="index"
          >
            <div class="label" :class="['icon_' + (index + 1)]">
              {{ item.label }}
            </div>
            <div class="desc">
             {{ item.value }}
            </div>
          </div>
        </div>
      </div>

      <div class="item item_6">
        <div class="title">销售员战力榜</div>
        <div class="detail">
          <ul class="rank_list">
            <li
              v-for="(item, index) in sellerData"
              :class="'rank_' + (index + 1)"
              :key="item.id"
            >
              <span class="rank_no"
                ><b>{{ item.rank }}</b></span
              ><span class="rank_name">{{ item.name }}</span>
              <div class="rank_bg">
                <span
                  class="rank_pecent"
                  :style="'width:' + item.value + '%'"
                ></span>
              </div>
              <span class="rank_num">{{ item.value }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="item item_8">
        <div
          class="map-container"
          style="width: 100%; height: 100%"
          ref="myEchart"
        ></div>
      </div>
      <div class="item item_7">
        <table class="table" cellspacing="0" cellspadding="0">
          <tr>
            <th></th>
            <th>排行</th>
            <th>战斗力<br />指数</th>
            <th>战力上岗<br />得分</th>
            <th>跟进完成<br />得分</th>
            <th>成交逾期<br />扣分</th>
            <th>跟进质量<br />爆灯得分</th>
            <th>成交<br />得分</th>
            <th>战败<br />得分</th>
          </tr>
          <tr v-for="item in listData" :key="item.id">
            <td @click="jumpToStore(item)">{{ item.name }}</td>
            <td>{{ item.rank }}</td>
            <td :style="'color:' + getColor(item.value1)">{{ item.value1 }}</td>
            <td :style="'color:' + getColor(item.value2)">{{ item.value2 }}</td>
            <td :style="'color:' + getColor(item.value3)">{{ item.value3 }}</td>
            <td>{{ item.value4 }}</td>
            <td :style="'color:' + getColor(item.value5)">{{ item.value5 }}</td>
            <td :style="'color:' + getColor(item.value6)">{{ item.value6 }}</td>
            <td>{{ item.value7 }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { postData } from "@/api/index";
import echarts from "echarts";
export default {
  data() {
    return {
      entryData: [],
      companyData: [],
      sellerData: [],
      listData: [],
    };
  },
  created() {
    this.getDataBySearch();
  },
  methods: {
    async getDataBySearch() {
       const query = {is_mobile:1, ...this.$route.query, ...this.$store.state.query };
      this.loading = true;
      const { data = {} } = await postData("/api/board_battle/company", query);
      const { list, core, date, seller, charts } = data || {};
      this.entryData = [
           { label: "战斗力指数", value: core.core1 },
        { label: "成交率", value: core.core2 },
        { label: "战败率", value: core.core3 },
        { label: "爆灯率", value: core.core4 },
        { label: "逾期率", value: core.core5 },
      ];
      this.listData = list;;
      this.sellerData = seller.slice(0, 9);
      this.initEcharts(charts, date);
    },
    initEcharts(dataList, dateList) {
      this.myEchart = echarts.init(this.$refs.myEchart);
         const legend = dataList.map(o => o.title) || [];
        const labelArr = dateList.map(o => o || []);
        const series = dataList.map((o, i) => ({
            name: o.title,
            type: 'line',
            smooth: true,
            showSymbol: false,
            data: o.value.map(o => o),
        }));

        const mapOpts = {
            title: {
                text: ''
            },
            color : ['#f00774','#04ecae','#2cc1ff','#fefc02','#ffad55','#8400ff','#16f7e4', '#950247'],
            legend: {
                data: legend,
                textStyle:{
                    color:"#fff"
                },
                selectedMode:false,
                icon:"roundRect",
                itemHeight:2
            },

            xAxis: {
                type: 'category',
                axisLine:{
                    show:false,

                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#fff',  //更改坐标轴文字颜色
                    }
                },

                data: labelArr
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#fff',  //更改坐标轴文字颜色
                    }
                },
                splitLine: {     //网格线
                    "show": false
                }

            },
            series
        };
      this.myEchart.setOption(mapOpts);
    },
    getColor(v) {
      if (v < 60) {
        return "red";
      }
      if (v >= 80) {
        return "green";
      }
      return "yellow";
    },
    jumpToStore(item) {
      this.$router.push({
        path: "/battle/store",
        query: { store_id: item.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/index.scss';
.container {
  background: url("../../assets/bg_battle.png");
  background-size: cover;

  background-position: bottom center;
  .content {
    .item {
      display: flex;
      flex-direction: column;
      margin-top: 0.185185rem /* 10/54 */;
      &.item_1 {
        width: 9.666667rem /* 522/54 */;
        height: 7.907407rem /* 427/54 */;
        background: url("../../assets/battle_map.png");

        background-size: cover;
      }
      &.item_2 {
        width: 9.666667rem /* 522/54 */;
        height: 5.240741rem /* 283/54 */;
        background: url("../../assets/board.png");

        background-size: cover;
        .detail::after{
              content: '';
              display: block;
              width: 33.33%; 
        }
        .itemDetail{
          width: 33.33%;
         
        }
      }
      &.item_7 {
        width: 9.666667rem /* 522/54 */;
      border:1px solid #144cb9;
        box-shadow: 0px 0px 5px #144cb9 inset;
        color: #00d5e9;
        background-size: cover;
        font-size: 0.222222rem /* 12/54 */;
        padding: 0.185185rem /* 10/54 */;
        th {
          max-width: 0.851852rem /* 46/54 */;
        }
      }
      &.item_8 {
        width: 9.666667rem /* 522/54 */;
        height: 7.314815rem /* 395/54 */;

        background: url("../../assets/battle_table2.png");
        background-size: cover;
      }
      &.item_6 {
        width: 9.666667rem /* 522/54 */;
        height: 7.685185rem /* 415/54 */;

        background: url("../../assets/battle_user.png");
        background-size: cover;
      }
     
     
      .title {
        margin-left: 0.481481rem /* 26/54 */;
        color: #00d5e9;
        font-size: 0.407407rem /* 22/54 */;
        height: 0.962963rem /* 52/54 */;

        line-height: 0.962963rem /* 52/54 */;
      }
      
    }
  }
}
</style>